import Axios from 'axios';
import React, { useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import {
  FormInput,
  FormItem,
  FormLabel,
  FormSelect,
  WideSubmitButton,
} from '../../ExpressAppUI';
import {
  getQueryVariable, StateOptions, toFullStateName, formatAmountAsNumber,
} from '../../helpers';
import { useFormState } from '../../hooks';
import { BondAmounts } from './BondAmounts';
import { StartAppContext } from '../StartAppContext';
import { Mixpanel } from '../../mixpanel';

export function CreateLead(props) {
  // Use Start App Context
  const { state, updateState } = React.useContext(StartAppContext);

  // Create our form Ref
  const form = React.useRef(false);

  // Determine if page if our bond is loaded yet
  const [loaded, setLoaded] = React.useState(false);

  // Ref used to display BondName
  const bondName = React.useRef(null);

  // Ref used for displaying bond State selection
  const isFederal = React.useRef(false);

  // Ref used for handling PPK bond amounts
  const isPPK = React.useRef(false);

  const isTitleBond = React.useRef(false);
  // State for submission
  const [submitting, setSubmitting] = React.useState(false);

  // The data for the union question (only asked on ERISA bonds)
  const [unionData, setUnion] = React.useState({
    askQuestion: getQueryVariable('isErisa') === 'true',
    showError: false,
    value: null,
  });

  // Tracking info (used for redirected partner leads and bonds that need resolution from website)
  const [tracking, setTracking] = React.useState({
    adgroup: '',
    campaign: '',
    keyword: '',
    leadFormType: '',
    referrer: '',
    source: '',
    visitorid: '',
  });

  const amountOnly = React.useRef(false);

  // Form Submission Handler called when form is VALID
  async function handleSubmit() {
    // Check for union jazz
    if (unionData.askQuestion && unionData.value === null) {
      // show error message
      setUnion({ ...unionData, showError: true });
    } else {
      // Mark submitting as true to prevent re-submits
      setSubmitting(true);

      // Create our lead, and append all tracking data to the request.
      // API returns the path the lead needs to go thru -- after its been created
      const result = await Axios.post('/start-application/create-lead', {
        ...values,
        tracking,
        unionData,
      });

      if (result?.data?.url) {
        // find type of event happening
        let event = '';
        if (isTitleBond) event =  'Title Bond Form Complete';
        if (values?.bondAmount && values?.bondAmount !== '0') {
        const selectedAmount = values?.amounts?.find(amount => amount.low <= values?.bondAmount && amount.high >= values?.bondAmount);
        if (selectedAmount?.instantPurchaseWeb) event =  'Instant Purchase Form Complete';
        }

        // Trigger Google Ads conversion with dataLayer event
        // IF instant purchase or title bond
        if (!!event) {
          window.dataLayer.push({
            bondId: values?.bondId,
            bondState: values?.bondState,
            bondAmount: values?.bondAmount,
            ecData: {
              email: values?.email,
              phone_number: values?.phone,
            },
            event,
          });
        }
      }

      if (result.data.type === 'application') {
        // Change URL to fill app path
        window.location = result.data.url;
      } else {
        // Use Reach Router navigation to navigate to Title-Bonds or EA
        props.navigate(result.data.url);
      }
    }
  }

  // Initialize our Form State, Error Handling, and grab needed methods.
  const [
    values,
    // eslint-disable-next-line no-unused-vars
    touched,
    errors,
    formBlur,
    submitForm,
    {
      hasError, isPristine, setValues, hasErrors, setErrors,
    },
  ] = useFormState(
    {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      bondAmount: '',
      amounts: '',
      bondState: '',
    },
    ['firstName', 'lastName', 'phone', 'email', 'bondAmount', 'bondState'],
    handleSubmit,
    form,
  );

  // Initialization of Component
  useEffect(() => {
    function storeTrackingData() {
      const trackingData = {
        adgroup: getQueryVariable('adgroup') || '',
        campaign: getQueryVariable('campaign') || '',
        gclid: getQueryVariable('gclid') || '',
        keyword: getQueryVariable('keyword') || '',
        referrer: getQueryVariable('referrer') || '',
        source: getQueryVariable('source') || '',
        utmCampaign: getQueryVariable('utmCampaign') || '',
        utmMedium: getQueryVariable('utmMedium') || '',
        utmSource: getQueryVariable('utmSource') || '',
        visitorid: getQueryVariable('visitorid') || '',
        // NOTE: this is the way that it is bc the value would be a bool as a string...
        cbDotcomClick: !!getQueryVariable('contractor'),
      };

      const trackProps = {
        'Lead Form Type': trackingData.leadFormType,
      };

      const partner = getQueryVariable('partnerId');

      if (partner) {
        // Tracking Data
        trackingData.source = partner;
        trackingData.referrer = 'Partner Lead';
      }

      setTracking(trackingData);

      Mixpanel.register(trackProps);
    }

    // A method that pings API for bond with bondID url Param and sets the componenet state
    async function getBond() {
      // Talk to API
      const data = await Axios.post('/start-application/get-bond', {
        bondId: getQueryVariable('bondId'),
      });

      // Our found bond
      const bond = data.data;

      // Initialize the object we will use to update form state
      const updateObj = { amounts: bond.amounts, bondId: bond._id.toString() };

      // Set BondName Ref (so we can display name of bond in form header)
      bondName.current = `${
        bond.state.length === 1 ? toFullStateName(bond.state[0]) : ''
      } ${bond.type || bond.name}`;

      // grab our query params if in url and set them into form state
      updateObj.firstName = getQueryVariable('firstName') || '';
      updateObj.lastName = getQueryVariable('lastName') || '';
      updateObj.phone = getQueryVariable('phone') || '';
      updateObj.email = getQueryVariable('email') || '';
      updateObj.bondState = getQueryVariable('bondState') || '';
      updateObj.bondAmount = formatAmountAsNumber(getQueryVariable('bondAmount')) || '';

      // Figure out if we have any PPK amounts
      bond.amounts.forEach((a, idx) => {
        let priceTag = 'Varies';
        a.companies.forEach((c) => {
          if (c.active && c.autoQuote && !c.needsUnderwriting) {
            priceTag = c.price;
            if (c.pricePerThousand) {
              priceTag = 'Varies';
              updateObj.amounts[idx].isPPK = true;
            }
          }
        });
        updateObj.amounts[idx].priceTag = priceTag;
      });

      // If we only have one amount, we can assume that is the selected amount
      if (bond.amounts.length === 1) {
        updateObj.bondAmount = bond.amounts[0].low.toString();
        if (updateObj.amounts[0].isPPK) {
          isPPK.current = true;
        }
      }

      // In the case our URL params did not send state over
      if (!updateObj.bondState) {
        // If the bond only has one state, assume that state
        if (bond.state.length === 1) {
          updateObj.bondState = bond.state[0];
        } else {
          // We need to prompt the user to select a state
          // isFederal controls the UI switch for bond state selection
          isFederal.current = true;
        }
      }

      // check if we are a title bond
      isTitleBond.current = (bond.categories.filter((c) => c.name.toUpperCase() === 'VEHICLE TITLE').length > 0);

      // If we have every value but the bond amount, don't ask for other lead information
      amountOnly.current = !!(!!updateObj.firstName
        && !!updateObj.lastName
        && !!updateObj.phone
        && !!updateObj.email
        && (!!getQueryVariable('needAmount') || !updateObj.bondAmount));

      // Set all our form state values
      setValues({ ...values, ...updateObj });

      // Update global Start App State that we are no longer loading -- displays UI
      updateState({ ...state, loading: false });
      // Update local state that we are no longer loading -- displays UI
      setLoaded(true);
      // Track Mixpanel
      const trackProps = {
        'Bond ID': bond._id,
        'Bond Type': bondName.current,
        'Bond State': updateObj.bondState,
        'Bond Version': bond.version,
      };

      if (getQueryVariable('contractor')) {
        trackProps.ContractorBonds = true;
      }

      Mixpanel.register(trackProps);
    }
    Mixpanel.track('Start App - Create Lead');
    storeTrackingData();
    getBond();
  }, []);

  function resetBondAmount() {
    setValues({ ...values, bondAmount: '' });
    setErrors({ ...errors, bondAmount: false });
  }
  return (
    <>
      {!loaded ? null : (
        <form ref={form}>
          <div class="panel-title text-center">{bondName.current}</div>

          {!isPristine() && hasErrors() && (
            <h6 className="error-text" style={{ textAlign: 'center' }}>
              Please Address Fields in Red
            </h6>
          )}

          {!amountOnly.current ? (
            // If we need to ask for contact Information, present those form inputs
            <>
              <FormItem>
                <FormLabel>First Name</FormLabel>
                {errors.firstName && (
                  <p className="small-error">{errors.firstName}</p>
                )}
                <FormInput
                  onBlur={formBlur}
                  hasError={hasError('firstName')}
                  name="firstName"
                  required
                  defaultValue={values.firstName}
                  placeholder="First Name"
                />
              </FormItem>
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                {errors.lastName && (
                  <p className="small-error">{errors.lastName}</p>
                )}
                <FormInput
                  onBlur={formBlur}
                  hasError={hasError('lastName')}
                  name="lastName"
                  required
                  defaultValue={values.lastName}
                  placeholder="Last Name"
                />
              </FormItem>
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                {errors.phone && <p className="small-error">{errors.phone}</p>}

                <MaskedInput
                  className={`${
                    hasError('phone') ? 'has-error' : ''
                  } form-input`}
                  name="phone"
                  required
                  onBlur={formBlur}
                  defaultValue={values.phone}
                  data-inputtype={'phone'}
                  placeholder="(000) 000-0000"
                  mask={[
                    '(',
                    /\d/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </FormItem>
              <FormItem>
                <FormLabel>Email Address</FormLabel>
                {errors.email && <p className="small-error">{errors.email}</p>}
                <FormInput
                  onBlur={formBlur}
                  hasError={hasError('email')}
                  name="email"
                  data-inputtype={'email'}
                  required
                  defaultValue={values.email}
                  placeholder="name@example.com"
                />
              </FormItem>
            </>
          ) : (
            // Otherwise hide the contact form inputs
            <>
              <input
                type="hidden"
                name="firstName"
                defaultValue={values.firstName}
                required
              />
              <input
                type="hidden"
                name="lastName"
                required
                defaultValue={values.lastName}
              />
              <input type="hidden" required name="email" defaultValue={values.email} />
              <input type="hidden" required name="phone" defaultValue={values.phone} />
            </>
          )}

          {isFederal.current ? (
            // If we need to ask for bondState, display a dropdown
            <FormItem>
              <FormLabel>Bond State</FormLabel>

              <FormSelect
                hasError={hasError('bondState')}
                name="bondState"
                required
                onChange={formBlur}
              >
                <option value="" disabled selected>
                  Please select a State
                </option>
                {StateOptions.map((option) => (
                  <option
                    key={`companyState-${option.value}`}
                    value={option.value}
                  >
                    {option.display}
                  </option>
                ))}
              </FormSelect>
            </FormItem>
          ) : (
            // Hidden BondState
            <input
              type="hidden"
              name="bondState"
              value={values.bondState}
              required
            />
          )}

          {/* Logic got overly complicated here, so broke this out into its own component. */}
          <BondAmounts
            amounts={values.amounts}
            currentAmount={values.bondAmount}
            errors={errors}
            formBlur={formBlur}
            hasError={hasError}
            isPPK={isPPK.current}
            isTitleBond={isTitleBond.current}
            resetBondAmount={resetBondAmount}
          />

          {unionData.askQuestion && (
            <FormItem key='erisa'>
            <FormLabel>
              Are you purchasing this bond on behalf of a union? <sup>*</sup>
            </FormLabel>
            {unionData.showError && <p className="small-error">Please select yes or no below</p>}
            <div className="radio-wrap">
              <input
                onChange={() => setUnion({ ...unionData, value: true })}
                className="toggle-radio"
                type="radio"
                id="confirmUnionYes"
                name="confirmUnion"
                defaultValue="Yes"
              />
              <label htmlFor="confirmUnionYes" className="toggle-label">
                Yes
              </label>
              <input
                onChange={() => setUnion({ ...unionData, value: false })}
                className="toggle-radio"
                type="radio"
                id="confirmUnionNo"
                name="confirmUnion"
                defaultValue="No"
              />
              <label htmlFor="confirmUnionNo" className="toggle-label">
                No
              </label>
            </div>
          </FormItem>
          )}

          <WideSubmitButton
            onClick={(e) => submitForm(e, () => window.scrollTo(0, 0))}
            disabled={submitting}
          >
            {submitting ? (
              <>
                {'Saving     '}
                <i class="fa fa-spinner fa-spin" />
              </>
            ) : (
              'Save and Continue'
            )}
          </WideSubmitButton>
        </form>
      )}
    </>
  );
}
